import { ReactComponent as Logo } from 'assets/images/Grupo-Multitecnica.svg';
import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeRequest } from 'utils/requests';

type FormState = {
  password: string;
};

type Params = {
  token: string;
};

const TrocarSenha = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>();
  const navigate = useNavigate();
  const { token } = useParams<Params>();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    const params: AxiosRequestConfig = {
      method: 'GET',
      url: `/usuarios/validar-token/${token}`,
      withCredentials: false,
    };

    makeRequest(params)
      .then(response => {
        if (response.data === false) {
          toast.error('Token inválido ou expirou!');
          navigate('/login');
        }
      })
      .catch(() => toast.error('Ocorreu um erro de rede!'));
  }, [navigate, token]);

  const onSubmit = (form: FormState) => {
    let password = form.password;
    const params: AxiosRequestConfig = {
      method: 'POST',
      url: `/usuarios/trocar-senha/${token}`,
      withCredentials: false,
      data: { password },
    };

    makeRequest(params)
      .then(response => {
        if (response.data === true) {
          toast.success('Senha alterada com sucesso!');
          navigate('/login');
        } else {
          toast.error('Ocorreu um erro ao trocar senha, tente mais tarde!');
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao trocar senha, tente mais tarde!');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
      <Logo className="img-fluid mb-4" />
      <h1 className="h3 fw-normal mb-4">Digite sua nova senha:</h1>
      <div className="form-floating mb-3 mt-1">
        <input
          type="password"
          className={`form-control bg-white ${errors.password ? 'is-invalid' : ''}`}
          {...register('password', { required: true })}
        />
        <label htmlFor="password">Senha</label>
      </div>
      <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">
        Salvar
      </button>
      <p className="mt-5 mb-3 text-muted">&copy; 2021</p>
    </form>
  );
};

export default TrocarSenha;
