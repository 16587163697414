import { AxiosRequestConfig } from 'axios';
import Breadcrumb from 'components/breadcrumb';
import Loader from 'components/loader';
import { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Data } from 'types/datas';
import { makeRequest } from 'utils/requests';
import { formatarDataToUTC, formatarStringToData } from 'utils/utils';
import Swal from 'sweetalert2';

type Form = {
  data: string;
  descricao: string;
};

const DataExceptions = () => {
  const [datas, setDatas] = useState<Data[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { register, handleSubmit } = useForm<Form>();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getDatas = useCallback(() => {
    setIsLoading(true);
    const params: AxiosRequestConfig = {
      method: 'GET',
      url: '/data-exceptions',
      withCredentials: true,
      params: {
        status: 2,
      },
    };
    makeRequest(params)
      .then(response => {
        setDatas(response.data.content);
      })
      .catch(response => toast.error('Ocorreu um erro ao verificar datas.'))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  const handleDelete = (data: Data) => {
    Swal.fire({
      title: 'Tem certeza que deseja\n apagar ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Não`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
          method: 'DELETE',
          url: `/data-exceptions/${data.id}`,
          withCredentials: true,
        };
        makeRequest(params)
          .then(() => {
            getDatas();
            toast.success('Data apagada com sucesso.');
          })
          .catch(() => toast.error('Ocorreu um erro ao verificar datas.'))
          .finally(() => setIsLoading(false));
      }
    });
  };

  const onSubmit = (data: Form) => {
    let datasArray = datas?.filter(
      x => x.data === formatarDataToUTC(data.data, '00:00'),
    );
    if (datasArray && datasArray.length > 0) {
      toast.error('Data ja cadastrada!');
      setShow(false);
    } else {
      setIsLoading(true);

      const params: AxiosRequestConfig = {
        method: 'POST',
        url: '/data-exceptions',
        withCredentials: true,
        data: {
          data: formatarDataToUTC(data.data, '00:00'),
          descricao: data.descricao,
        },
      };

      makeRequest(params)
        .then(() => {
          toast.success('Data salva com sucesso!');
          setShow(false);
          getDatas();
        })
        .catch(response => toast.error('Ocorreu um erro ao verificar datas.'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Breadcrumb controller="Home" action="Exceções Dia" />
      <div className="p-5 rounded bg-white shadow">
        <div className="row">
          <div className="col-lg-6">
            <h1>Exceções de data</h1>
          </div>
          <div className="col-lg-6 text-end">
            <button className="btn btn-primary btn-lg" onClick={handleShow}>
              Adicionar
            </button>
          </div>
        </div>
        <div className="row mt-5">
          {isLoading ? (
            <Loader />
          ) : (
            <table className="table table-striped table-responsive">
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Descrição</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {datas?.map(data => (
                  <tr key={data.id}>
                    <td>{formatarStringToData(data.data)}</td>
                    <td>
                      <p>{data.descricao}</p>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger text-uppercase"
                        onClick={() => handleDelete(data)}
                      >
                        Excluir
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar nova restrição de data:</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <input
              type="date"
              placeholder="Data"
              className="form-control bg-white"
              {...register('data', { required: true })}
            />
            <input
              type="text"
              placeholder="descricao"
              className="form-control bg-white mt-3"
              {...register('descricao')}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default DataExceptions;
