import {useCallback, useEffect, useState} from "react";
import {ParametrosResponse} from "types/parametros";
import {AxiosRequestConfig} from "axios";
import {makeRequest} from "utils/requests";
import {toast} from "react-toastify";
import Breadcrumb from "components/breadcrumb";
import Loader from "components/loader";
import {useNavigate} from "react-router-dom";

const ListConfiguracoes = () => {
    const [configuracoes, setConfiguracoes] = useState<ParametrosResponse>();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const getConfiguracoes = useCallback(() => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: "GET",
            url: "/parametros",
            withCredentials: true
        }
        makeRequest(params)
            .then(response => {
                setConfiguracoes(response.data);
            })
            .catch(response => toast.error("Ocorreu um erro ao carregar parametros."))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        getConfiguracoes();
    } , [getConfiguracoes]);


    const handleEdit = (id: number) => {
        navigate(`/painel/configuracoes/${id}`);
    }


    return (
        <>
            <Breadcrumb controller="Configurações" action="Lista"/>
            <div className="p-5 rounded bg-white shadow">
                <div className="input-group row">
                    <div className="col-lg-6">
                        <h3>Lista de janelas</h3>
                    </div>
                </div>
                {isLoading ? <Loader /> : (
                    <>
                        <table className="table table-striped table-hover fs-6 table-responsive">
                            <thead>
                            <tr>
                                <th scope="col">Janela</th>
                                <th scope="col">Dias Min.</th>
                                <th scope="col">Dias Max.</th>
                                <th scope="col">Horario Min.</th>
                                <th scope="col">Horario Max.</th>
                                <th scope="col">Intervalo de agendamento</th>
                                <th scope="col">Dias para Cancelamento</th>
                                <th scope="col">Considerar data atual</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {configuracoes?.content.map((config) =>
                                <tr key={config.id}>
                                    <th>{config.janela}</th>
                                    <td>{config.diasMin}</td>
                                    <td>{config.diasMax}</td>
                                    <td>{config.horarioMin}</td>
                                    <td>{config.horarioMax}</td>
                                    <td>{config.intervaloHora}</td>
                                    <td>{config.diasToCancel}</td>
                                    <td>{config.considerarDataAtual ? 'Sim' : 'Não'}</td>
                                    <td>
                                        <button type="button" className="btn btn-primary me-1" onClick={() => handleEdit(config.id)}>Editar</button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
}

export default ListConfiguracoes;