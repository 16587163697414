import { ReactComponent as Logo } from 'assets/images/Grupo-Multitecnica.svg';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { removeAuthData } from 'utils/storage';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';

const Header = () => {
    const navigate = useNavigate();
    const { hasAnyRoles } = useAuth();

    const handleLogout = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        event.preventDefault();

        Swal.fire({
            title: 'Deseja realmente sair?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then(result => {
            if (result.isConfirmed) {
                removeAuthData();
                navigate('/login');
            }
        });
    };

    return (
        <Navbar bg="light" expand="lg" className="shadow-sm">
            <Container fluid>
                <Navbar.Brand href="/">
                    <Logo width="200px" height="50px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto fw-bold">
                        <Link to="/painel/listar-pedidos" className="nav-link">
                            Pedidos
                        </Link>
                        {hasAnyRoles(['ROLE_OPERATOR', 'ROLE_ADMIN']) && (
                            <Link to="/painel/agendamentos" className="nav-link">
                                Agendamentos
                            </Link>
                        )}
                        {hasAnyRoles(['ROLE_ADMIN']) && (
                            <Link to="/painel/excecoes-data" className="nav-link">
                                Exceções de data
                            </Link>
                        )}
                        {hasAnyRoles(['ROLE_OPERATOR', 'ROLE_ADMIN']) && (
                            <NavDropdown title="Relatorios" id="basic-nav-dropdown">
                                <Link to="/painel/relatorio-pedidos" className="nav-link">
                                    Pedidos
                                </Link>
                                <NavDropdown.Divider />
                                <Link to="/painel/relatorio-agendamentos" className="nav-link">
                                    Agendamentos
                                </Link>
                            </NavDropdown>
                        )}
                        {hasAnyRoles(['ROLE_ADMIN']) && (
                            <Link to="/painel/configuracoes" className="nav-link">
                                Configurações
                            </Link>
                        )}
                    </Nav>
                    <div className="d-flex">
                        <a
                            href="#logout"
                            className="btn btn-primary px-5"
                            onClick={handleLogout}
                        >
                            {' '}
                            SAIR
                        </a>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
