import Header from "components/header";
import { Container } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "utils/auth";

const PainelLayout = () => { 
    return !isAuthenticated() ? <Navigate to={'/login'} /> : (
        <>  
            <Header />
            <Container fluid className="mt-3">
                <Outlet />
            </Container>
        </>
    )
}

export default PainelLayout;