import Breadcrumb from "components/breadcrumb";
import { Link } from "react-router-dom";
import "./styles.scss";

const Erro404 = () => {
    // let location = useLocation();
    return (
        <>
            <Breadcrumb controller="Erro" action="404" />
            <div className="p-5 rounded bg-white shadow">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h1>
                                Oops!</h1>
                            <h2>
                                404 Not Found</h2>
                            <div className="error-details">
                                Desculpe, ocorreu um erro, página solicitada não encontrada!
                                <br />
                                {/* {location.pathname} */}
                            </div>
                            <div className="error-actions">
                                <Link to="/painel/listar-pedidos" className="btn btn-primary btn-lg"> 
                                <span className="bi bi-chevron-double-left"></span>  Voltar ao inicio</Link>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Erro404;