import jwtDecode from "jwt-decode";
import { getAuthData } from "./storage";

export type TokenData = {
    exp: number;
    user_name: string;
    authorities: AuthRole[];
}

export type AuthRole = 'ROLE_ADMIN' | 'ROLE_OPERATOR';

export const getTokenData = () : TokenData | undefined => {
    const { access_token } = getAuthData();
    try{
        return jwtDecode(access_token) as TokenData;
    }catch (error){
        return undefined;
    }
}

export const isAuthenticated = ():boolean =>{
    const tokenDataDecoded = getTokenData();
    return !!(tokenDataDecoded && tokenDataDecoded.exp * 1000 > Date.now());
}



