import { Navigate, Outlet } from "react-router-dom";
import { AuthRole } from "utils/auth";
import {useAuth} from "contexts/AuthContext";


type Props = {
    role?: AuthRole[];
}

const HasPermissao = ({role} : Props) => {
    const {hasAnyRoles} = useAuth();

    if(role && role.length > 0 && hasAnyRoles(role)){
        return <Outlet/>
    }else{
        return <Navigate to="/painel" replace />;
    }
}


export default HasPermissao;