import moment, { Moment } from 'moment';

const TIME_ZONE = '-03:00';

export const geraHorarios = (minHora: string, maxHora: string, intervalo: string) => {
  let minTemp = minHora.split(':');
  let maxTemp = maxHora.split(':');
  let data_ini = moment().hours(parseInt(minTemp[0])).minutes(parseInt(minTemp[1]));
  let data_fin = moment().hours(parseInt(maxTemp[0])).minutes(parseInt(maxTemp[1]));
  const horarios = [];
  for (data_ini; data_ini < data_fin; data_ini.add(intervalo, 'h')) {
    horarios.push(data_ini.format('HH:mm'));
  }
  return horarios;
};

export const formatarStringToData = (data: string) => {
  const date = moment(data);
  return date.format('DD/MM/YYYY');
};

export const formatarStringToDataEHora = (data: string) => {
  const date = moment(data);
  return date.format('DD/MM/YYYY - HH:mm');
};

export const formatarStringToHora = (data: string) => {
  const date = moment(data);
  return date.format('hh:mm');
};

export const formatarData = (data: Moment) => {
  const date = moment(data);
  return date.format('YYYY-MM-DD');
};

export const formatarDataToUTC = (data: string, horario: string) => {
  const date = moment(data + ' ' + horario + TIME_ZONE, 'YYYYMMDD HH:mm-03:00');
  return date.parseZone().utc().format();
};

export const verificadata = (dataEntrega: string, dataBase: string, minDate?: number, maxDate?: number) => {
  let dataE = moment(dataEntrega);
  let dataMinima = moment(dataBase).subtract(minDate, 'd');
  let dataMaxima = moment(dataBase).add(maxDate, 'd');
  if (dataE <= dataMaxima && dataE >= dataMinima) {
    return true;
  } else {
    return false;
  }
};

export const dataMinAndMax = (dataEntrega: string, minDate?: number, maxDate?: number) => {
  let dateNow = moment();
  let dataMinima = moment(dataEntrega).subtract(minDate, 'd');
  let dataMaxima = moment(dataEntrega).add(maxDate, 'd');
  if (dataMinima < dateNow) {
    dataMinima = moment();
  }
  return [dataMinima, dataMaxima];
};

export const subtrair = (valor1: string | number, valor2: string | number, valor3: string | number) => {
  const saldo = Number(valor1) - Number(valor2);
  const saldo2 = Number(valor3) - Number(valor2);
  const saldo3 = saldo - (saldo2 < 0 ? 0 : saldo2);
  return saldo3;
};
