import { AxiosRequestConfig } from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Carregamento, CarregamentoItem } from 'types/carregamentos';
import { Pedido } from 'types/pedidos';
import { makeRequest } from 'utils/requests';
import { formatarDataToUTC } from 'utils/utils';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from 'date-fns/locale/pt-BR';
import moment from 'moment';

registerLocale('pt-BR', ptBR);

type Props = {
    pedidos: Pedido[];
    setIsLoading: Function;
    cgc: string;
    cnpj: string;
};

type FormState = {
    date: Date;
    horario: string;
    placaPrevista: string;
};


const Calendario = ({ pedidos, setIsLoading, cgc, cnpj }: Props) => {
    const [listDatas, setListDatas] = useState<string[]>();
    const [horarios, setHorarios] = useState<string[]>([]);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm<FormState>({ reValidateMode: 'onChange' });
    const { hasAnyRoles } = useAuth();

    const montaDatas = useCallback(() => {
        // setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'POST',
            url: '/carregamento/datas/disponiveis',
            withCredentials: true,
            data: pedidos,
        };

        makeRequest(params)
            .then((response) => {
                setListDatas(response.data);
            })
            .catch(error => toast.error(error.response.data.message || 'Erro ao buscar datas disponíveis!'))
            // .finally(() => setIsLoading(false));

    }, [pedidos]);


    useEffect(() => {
        montaDatas();
    }, [montaDatas]);

    const dataChange = async (value: string) => {
        const params: AxiosRequestConfig = {
            method: 'POST',
            url: '/carregamento/horarios/disponiveis',
            withCredentials: true,
            data: pedidos,
            params: {
                data: value,
            },
        };

        makeRequest(params)
            .then((response) => {
                setHorarios(response.data);
                setValue('horario', 'SELECIONARHORA');
            })
            .catch(error => toast.error(error.response.data.message || 'Erro ao buscar horarios disponíveis!'))

    };

    const onSubmit = async (data: FormState) => {
        if (!data.date || !data.horario || data.horario === 'SELECIONARHORA') {
            toast.error('Você precisa selecionar uma data e hora.');
            return;
        }
        if (!data.placaPrevista) {
            toast.error('Você precisa preencher a placa.');
            return;
        }
        if (data.placaPrevista.search(/^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/) > 0) {
            toast.error('Formato de placa inválido.');
            return;
        }
        saveCarregamento(formatarDataToUTC(moment(data.date).format("YYYYMMDD"), data.horario), data.placaPrevista);
    };

    const saveCarregamento = (dataUtc: string, placa: string) => {
        setIsLoading(true);
        let pedidosTemp: CarregamentoItem[] = pedidos.map(x => {
            let item: CarregamentoItem = {
                id: 0,
                pedidoId: `${x.id}`,
                quantidade: `${x.agendamentoAtual}`,
            };
            return item;
        });

        const data: Carregamento = {
            id: 0,
            dataAgendamento: dataUtc,
            placaPrevista: placa.toUpperCase(),
            status: 0,
            items: pedidosTemp,
        };

        const params: AxiosRequestConfig = {
            method: 'POST',
            url: '/carregamento',
            withCredentials: true,
            data,
            params: {
                cgc: hasAnyRoles(['ROLE_ADMIN']) ? cgc : '',
                cnpj,
            },
        };

        makeRequest(params)
            .then(() => {
                toast.success('Agendamento enviado com sucesso!');
                navigate('/painel/agendamento-enviado', {
                    state: { fromSelect: true },
                });
            })
            .catch(error => toast.error(error.response.data.message || 'Erro ao enviar agendamento!'))
            .finally(() => setIsLoading(false));
    };

    const allowDate = (date: Date) => {
        const dataCalendar = moment(date).format('YYYYMMDD');
        return listDatas ? listDatas.includes(dataCalendar) : false;
    };

    const CustomCalendarInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <input
            onClick={onClick}
            ref={ref}
            value={value}
            type="text"
            readOnly
            placeholder="Selecionar Data"
            className="form-control bg-white border-3 border-dark"

        />

    ));

    return (
        <>
            <div className="row">
                <h1>Agende seu carregamento</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="row mt-3">
                <div className="col-lg-3 mt-3 mt-xl-0">
                    <Controller
                        name="date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                selected={field.value}
                                onChange={(date) => {
                                    field.onChange(date);
                                    dataChange(moment(date).format('YYYYMMDD'));
                                }}
                                filterDate={allowDate}
                                dateFormat="dd/MM/yyyy"
                                locale="pt-BR"
                                placeholderText="Selecionar Data"
                                customInput={<CustomCalendarInput />}
                                dateFormatCalendar='MMMM yyyy'
                                todayButton={"Hoje"}
                            // withPortal
                            />
                        )}
                    />


                </div>
                <div className="col-lg-3 mt-3 mt-xl-0">
                    <div className="input-group mb-3">
                        <select className="form-select bg-white border-3 border-dark" {...register('horario', { required: true })}>
                            <option value="SELECIONARHORA">Selecionar Horário</option>
                            {horarios?.map(x => (
                                <option value={x} key={x}>
                                    {x}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 mt-3 mt-xl-0">
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            maxLength={7}
                            placeholder="Placa Esperada"
                            className="form-control bg-white border-3 border-dark"
                            {...register('placaPrevista', {
                                max: 7,
                                min: 7,
                                pattern: /^(?![0-9]{7})[0-9a-zA-Z]{7}$/i,
                            })}
                        />
                        {errors.placaPrevista?.type === 'pattern' && (
                            <div className="invalid-feedback d-block">Formato de placa inválido!</div>
                        )}
                    </div>
                </div>
                <div className="col-lg-3 mt-3 mt-xl-0 text-center text-lg-start">
                    <input type="submit" className="btn btn-primary text-uppercase w-100" value="Agendar" />
                </div>
            </form>
        </>
    );
};

export default Calendario;
