import {ReactComponent as Logo} from 'assets/images/Grupo-Multitecnica.svg';
import {useState} from "react";
import {Spinner} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {Link, Navigate} from "react-router-dom";
import {isAuthenticated} from 'utils/auth';
import {useAuth} from "contexts/AuthContext";

type FormState = {
    username: string;
    password: string;
}

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormState>({
        defaultValues:{
            username: '',
            password: ''
        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const { signIn } = useAuth();

    const onSubmit = (data: FormState) => {
        setIsLoading(true);
        data.username = data.username.replace(/\D/g, "");
        signIn(data);
        setIsLoading(false);
    }
    

    return isAuthenticated() ? <Navigate to={'/painel'} /> : (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation text-center">
                <Logo className="img-fluid mb-4 d-flex" />
                {isLoading ? <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> : (
                <>
                    <h1 className="h3 fw-normal">Digite seu CNPJ</h1>
                    <div className="form-floating mb-3 mt-1 text-start">
                        <input
                            type="text"
                            placeholder="CNPJ"
                            className={`form-control bg-white ${errors.username ? 'is-invalid' : ''}`}
                            {...register("username", { required: true })}
                            />
                        <label htmlFor="username">CNPJ</label>
                    </div>
                    <div className="form-floating mb-3 mt-1 text-start">
                        <input
                            type="password"
                            placeholder="SENHA"
                            className={`form-control bg-white ${errors.password ? 'is-invalid' : ''}`}
                            {...register("password", { required: true })}
                            />
                        <label htmlFor="username">SENHA</label>
                    </div>
                    <Link to="/esqueci-a-senha" className="text-decoration-none fw-bold">Esqueceu a senha ?</Link>
                    <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Login</button>
                </>
                )}
                <p className="mt-5 mb-3 text-muted">&copy; 2021</p>
            </form>
        </>
    )

}

export default Login;