
import AuthLayout from 'components/authLayout';
import PainelLayout from 'components/painelLayout';
import EsqueciSenha from 'pages/auth/esqueciSenha';
import Login from 'pages/auth/login';
import Logout from 'pages/auth/logout/Logout';
import TrocarSenha from 'pages/auth/trocarSenha';
import HasPermissao from 'pages/painel';
import Agendamentos from 'pages/painel/agendamentos';
import Agradecimento from 'pages/painel/agradecimento';
import EditConfiguracao from 'pages/painel/configuracoes/edit';
import Erro404 from 'pages/painel/error404';
import DataExceptions from 'pages/painel/excessoesData';
import Pedidos from 'pages/painel/pedidos';
import SelectData from 'pages/painel/selectData';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import ListConfiguracoes from "pages/painel/configuracoes/list";
import {useAuth} from "contexts/AuthContext";
import RelatorioPedidos from 'pages/painel/relatorioPedido';
import RelatorioAgendamento from 'pages/painel/realtorioAgendamento';

const  App = ()  => {
  const {hasAnyRoles} = useAuth();
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<Navigate to={'login'} />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="esqueci-a-senha" element={<EsqueciSenha />} />
        <Route path="trocar-senha">
          <Route index element={<Navigate to="/login" replace />} />
          <Route path=":token" element={<TrocarSenha />} />
        </Route>
        <Route path="*" element={<Navigate to={'login'} />} />
      </Route>
      <Route path="/painel" element={<PainelLayout />}>
        <Route index element={hasAnyRoles(["ROLE_ADMIN"]) ? <Navigate to={'/painel/agendamentos'} /> : <Navigate to={'/painel/listar-pedidos'} /> } />
        <Route path="listar-pedidos" element={<HasPermissao role={['ROLE_OPERATOR','ROLE_ADMIN']} />} >
          <Route index element={<Pedidos />} />
        </Route>
        <Route path="selecionar-data" element={<HasPermissao role={['ROLE_OPERATOR','ROLE_ADMIN']} />} >
          <Route index element={<SelectData />} />
        </Route>
        <Route path="agendamento-enviado" element={<HasPermissao role={['ROLE_OPERATOR', 'ROLE_ADMIN']} />} >
          <Route index element={<Agradecimento />} />
        </Route>
        <Route path="agendamentos" element={<HasPermissao role={['ROLE_ADMIN','ROLE_OPERATOR']} />} >
          <Route index element={<Agendamentos />} />
        </Route>
        <Route path="configuracoes" element={<HasPermissao role={['ROLE_ADMIN']} />} >
          <Route index element={<ListConfiguracoes />} />
          <Route path=":id" element={<EditConfiguracao />} />
        </Route>
        <Route path="excecoes-data" element={<HasPermissao role={['ROLE_ADMIN']} />} >
          <Route index element={<DataExceptions />} />
        </Route>
        <Route path="relatorio-pedidos" element={<HasPermissao role={['ROLE_ADMIN','ROLE_OPERATOR']} />} >
          <Route index element={<RelatorioPedidos />} />
        </Route>
        <Route path="relatorio-agendamentos" element={<HasPermissao role={['ROLE_ADMIN','ROLE_OPERATOR']} />} >
          <Route index element={<RelatorioAgendamento />} />
        </Route>
        <Route path="*" element={<Erro404 />} />
      </Route>
      <Route index element={<Navigate to={'/login'} />} />
    </Routes>
  );
}

export default App;
