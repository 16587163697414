import { AxiosRequestConfig } from 'axios';
import Breadcrumb from 'components/breadcrumb';
import Loader from 'components/loader';
import { useAuth } from 'contexts/AuthContext';
import $ from 'jquery';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Empresa } from 'types/empresa';
import { Pedido } from 'types/pedidos';
import { makeRequest } from 'utils/requests';
import { formatarStringToData, subtrair } from 'utils/utils';
import Select from 'react-select'

const Pedidos = () => {
    const [pedidos, setPedidos] = useState<Pedido[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [carregamentoPedidos, setCarregamentoPedidos] = useState<Pedido[]>([]);
    const fromList = true;
    const navigate = useNavigate();
    const { hasAnyRoles, parametros } = useAuth();
    const [usuarios, setUsuarios] = useState<any[]>([]);
    const [usuario, setUsuario] = useState<string>('none');
    const [empresas, setEmpresas] = useState<Empresa[]>([]);
    const [empresa, setEmpresa] = useState<string>();
    const [buscaNumeroPedido, setBuscaNumeroPedido] = useState<string>("");
    const options = usuarios.map(usuario => ({value: usuario.cnpj, label: `${usuario.nome} - ${usuario.cnpj}`}));
    const pedidosFiltrados = pedidos.filter(pedido => pedido.pedido.toString().includes(buscaNumeroPedido));



    const getEmpresas = useCallback(() => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/integracao/getEmpresas',
            withCredentials: true,
        };
        makeRequest(params)
            .then(response => {
                setEmpresas(response.data);
            })
            .catch(() => toast.error('Ocorreu um erro ao verificar empresas.'))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        getEmpresas();
    }, [getEmpresas]);


    const getUsuarios = useCallback(() => {
        if (!hasAnyRoles(['ROLE_ADMIN'])) return;
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/usuarios',
            withCredentials: true,
            params: {
                size: 2000,
                sort: 'nome,asc',
            },
        };
        makeRequest(params)
            .then(response => {
                setUsuarios(response.data.content);
            })
            .catch(response => toast.error('Ocorreu um erro ao verificar usuários.'))
            .finally(() => setIsLoading(false));
    }, [hasAnyRoles]);



    useEffect(() => {
        getUsuarios();
    }, [getUsuarios]);


    const getPedidos = useCallback(() => {
        if (!empresa || empresa === 'none') return;
        if (hasAnyRoles(['ROLE_ADMIN']) && (!usuario || usuario === 'none')) {
            setPedidos([]);
            return;
        };
        if (!empresa) return;
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/pedidos',
            withCredentials: true,
            params: {
                size: 2000,
                cgc: hasAnyRoles(['ROLE_ADMIN']) && usuario !== 'none' ? usuario : '',
                cnpj: empresa,
                // sort: 'data_entrega,asc' 
            },
        };
        makeRequest(params)
            .then(response => {
                setPedidos(response.data);
            })
            .catch(response => toast.error('Ocorreu um erro ao verificar pedidos.'))
            .finally(() => {
                setBuscaNumeroPedido("");
                setCarregamentoPedidos([]);
                handelLimparSelecao();
                setIsLoading(false)
            });
    }, [empresa, hasAnyRoles, usuario]);

    useEffect(() => {
        getPedidos();
    }, [getPedidos]);

    const handleCheked = (pedido: Pedido, status: boolean) => {
        if (status) {
            setCarregamentoPedidos([...carregamentoPedidos, pedido]);
        } else {
            setCarregamentoPedidos(carregamentoPedidos.filter(pr => pr.id !== pedido.id));
        }
    };

    const handlePushData = () => {
        if (!empresa || empresa === 'none') {
            toast.warn('Você precisa selecionar uma empresa!');
            return;
        }
        if (carregamentoPedidos.length > 0) {
            // -- Lógica para verificar se existe alguma data inválida
            const dataAtual = Date.now();
            for (const pedido of carregamentoPedidos) {
                const dataEntrega = pedido.dataEntrega;
                const dataFormatada = moment(dataEntrega, 'YYYYMMDD').format('YYYY-MM-DD');
                const dataMaxima = new Date(dataFormatada);
                dataMaxima.setHours(dataMaxima.getHours() + 3);
                dataMaxima.setDate(dataMaxima.getDate() + parametros.diasMax);

                if (dataMaxima < new Date(dataAtual)) {
                    Swal.fire({
                        title: 'Data Inválida',
                        text: `Pedido nº ${pedido.pedido} está com a data de necessidade vencida!`,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
            }

            // -- Lógica para permitir carregamentos só com intervalos de entrega coincidentes
            let datas = [];
            for (let i = 0; i < carregamentoPedidos.length; i++) {
                const dataFormatada = moment(carregamentoPedidos[i].dataEntrega, 'YYYYMMDD').format('YYYY-MM-DD');
                const dataEntrega = new Date(dataFormatada);
                dataEntrega.setHours(dataEntrega.getHours() + 3);
                datas.push(dataEntrega);
            }

            // Encontrar a menor e a maior necessidade
            const menorData = datas.reduce((menor, data) => (data < menor ? data : menor));
            const maiorData = datas.reduce((maior, data) => (data > maior ? data : maior));

            // -- Encontra a data máxima de entrega da menor necessidade
            const novaMenorData = new Date(menorData);
            novaMenorData.setDate(novaMenorData.getDate() + parametros.diasMax);
            // -- Encontra a data mínima de entrega da maior necessidade
            const novaMaiorData = new Date(maiorData);
            novaMaiorData.setDate(novaMaiorData.getDate() - parametros.diasMin);

            if (novaMenorData < novaMaiorData) {
                Swal.fire({
                    title: 'Tolerância de Datas!',
                    text: 'Verifique a tolerância limite entre data de necessidade e data de entrega para os pedidos selecionados. Em caso de dúvidas procure o comprador responsável!',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                });
                return;
            }

            navigate('/painel/selecionar-data', {
                state: { carregamentoPedidos, fromList, cgc: hasAnyRoles(['ROLE_ADMIN']) && usuario !== 'none' ? usuario : '', cnpj: empresa },
            });
        } else {
            toast.warn('Você precisa selecionar um pedido!');
        }
    };

    const handelLimparSelecao = () => {
        setCarregamentoPedidos([]);
        $('input[type="checkbox"]').prop('checked', false);
    };

    const handleChangeUser = (e: string) => {
        setUsuario(e);
    }

    const handleChangeEmpresa = (e: string) => {
        setEmpresa(e);
    }

    const handleChangeBuscarPedido = (e: string) => {
       //mascara para somente numeros positivos sem caracteres especiais
        setBuscaNumeroPedido(e.replace(/[^0-9]/g, ''));
    }


    const calculaSaldo = (pedido: Pedido) => {
        return subtrair(pedido.quantidade, pedido.quantAgendada, pedido.quantEntregada);
    }

   


    return (
        <>
            <Breadcrumb controller="Pedidos" action="Lista" />
            <div className="p-5 rounded bg-white shadow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row mb-5">
                            <h1>Monte seu carregamento</h1>
                        </div>
                        <div className="input-group row mb-3">
                            <div className="col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar por numero do pedido"
                                    value={buscaNumeroPedido}
                                    onChange={e => handleChangeBuscarPedido(e.target.value)}
                                />
                            </div>
                        </div>
                        {
                            hasAnyRoles(['ROLE_ADMIN']) &&
                            <div className="input-group row mb-3">
                                <div className="col-lg-10">
                                    <Select 
                                        options={options} 
                                        value={options.find(option => option.value === usuario)}
                                        onChange={e => handleChangeUser(e?.value || 'none')}
                                        placeholder="Selecione o Usuário"
                                        styles={{
                                            menuList: (provided) => ({ ...provided, maxHeight: 500,}),
                                            menu: (provided) => ({ ...provided, zIndex: 9999,}),
                                        }}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <input
                                        type="button"
                                        className="btn btn-primary text-uppercase w-100"
                                        value="Limpar Filtro"
                                        onClick={() => handleChangeUser('none')}
                                    />
                                </div>
                            </div>
                        }
                        <div className="input-group row mb-3">
                            <div className="col-lg-12">
                                <select
                                    className="form-select bg-white"
                                    onChange={e => handleChangeEmpresa(e.target.value)}
                                    value={empresa || 'none'}
                                >
                                    <option value={'none'} disabled>
                                        Selecione a Empresa
                                    </option>
                                    {empresas.map((empresa, i) => (
                                        <option key={i} value={empresa.cnpj}>
                                            {`${empresa.nome} - ${empresa.cnpj}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover fs-6">
                                <thead>
                                    <tr>
                                        <th scope="col" className="d-none d-lg-table-cell">Nº Pedido</th>
                                        <th scope="col">Produto</th>
                                        <th scope="col" className="d-none d-lg-table-cell">Descrição</th>
                                        <th scope="col">Quantidade disponivel</th>
                                        <th scope="col">Quantidade Total</th>
                                        <th scope="col">Necessidade</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pedidosFiltrados.map(pedido => (
                                        <tr key={pedido.id}>
                                            <th className="d-none d-lg-table-cell">{pedido.pedido}</th>
                                            <td>{pedido.produto}</td>
                                            <td className="d-none d-lg-table-cell">{pedido.descricao}</td>
                                            <td>
                                                {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 5,
                                                    useGrouping: false,
                                                }).format(calculaSaldo(pedido))}
                                            </td>
                                            <td>
                                                {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 5,
                                                    useGrouping: false,
                                                }).format(Number(pedido.quantidade))}
                                            </td>
                                            <td>{formatarStringToData(pedido.dataEntrega)}</td>

                                            <td>
                                                <div className="mb-3 form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input selecteds"
                                                        onChange={e => handleCheked(pedido, e.target.checked)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="input-group row text-end mt-5">
                            <div className="col-lg-12">
                                <button
                                    type="reset"
                                    className="btn btn-danger me-2 btn-lg text-uppercase col-lg-3"
                                    onClick={() => handelLimparSelecao()}
                                >
                                    Limpar Seleção
                                </button>
                                <button className="btn btn-primary btn-lg text-uppercase col-lg-3" onClick={() => handlePushData()}>
                                    Gerar Carregamento
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Pedidos;
