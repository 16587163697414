import { AxiosRequestConfig } from "axios";
import Breadcrumb from "components/breadcrumb";
import Loader from "components/loader";
import { useAuth } from "contexts/AuthContext";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Empresa } from "types/empresa";
import { Pedido } from "types/pedidos";
import { makeRequest } from "utils/requests";
import Select from "react-select";
import { formatarStringToData } from "utils/utils";

const RelatorioPedidos = () => {
    const [pedidos, setPedidos] = useState<Pedido[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { hasAnyRoles } = useAuth();
    const [usuarios, setUsuarios] = useState<any[]>([]);
    const [usuario, setUsuario] = useState<string>('none');
    const [empresas, setEmpresas] = useState<Empresa[]>([]);
    const [empresa, setEmpresa] = useState<string>();
    const [buscaNumeroPedido, setBuscaNumeroPedido] = useState<string>("");
    const [buscaDescricaoProduto, setBuscaDescricaoProduto] = useState<string>("");
    const options = usuarios.map(usuario => ({ value: usuario.cnpj, label: `${usuario.nome} - ${usuario.cnpj}` }));



    const getEmpresas = useCallback(() => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/integracao/getEmpresas',
            withCredentials: true,
        };
        makeRequest(params)
            .then(response => {
                setEmpresas(response.data);
            })
            .catch(() => toast.error('Ocorreu um erro ao verificar empresas.'))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        getEmpresas();
    }, [getEmpresas]);


    const getUsuarios = useCallback(() => {
        if (!hasAnyRoles(['ROLE_ADMIN'])) return;
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/usuarios',
            withCredentials: true,
            params: {
                size: 2000,
                sort: 'nome,asc',
            },
        };
        makeRequest(params)
            .then(response => {
                setUsuarios(response.data.content);
            })
            .catch(response => toast.error('Ocorreu um erro ao verificar usuários.'))
            .finally(() => setIsLoading(false));
    }, [hasAnyRoles]);



    useEffect(() => {
        getUsuarios();
    }, [getUsuarios]);


    const getPedidos = () => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/pedidos/relatorio',
            withCredentials: true,
            params: {
                size: 2000,
                cgc: hasAnyRoles(['ROLE_ADMIN']) && usuario !== 'none' ? usuario : '',
                cnpj: empresa,
                numPedido: buscaNumeroPedido,
                descProduto: buscaDescricaoProduto,
                // sort: 'data_entrega,asc' 
            },
        };
        makeRequest(params)
            .then(response => {
                setPedidos(response.data);
            })
            .catch(response => toast.error('Ocorreu um erro ao verificar pedidos.'))
            .finally(() => {
                setIsLoading(false)
            });
    }


    const handleChangeUser = (e: string) => {
        setUsuario(e);
    }

    const handleChangeEmpresa = (e: string) => {
        setEmpresa(e);
    }

    const handleClearFilter = () => {
        setEmpresa('');
        setUsuario('none');
        setBuscaNumeroPedido('');
        setBuscaDescricaoProduto('');
    }

    const handleChangeBuscarDescricaoProduto = (e: string) => {
        setBuscaDescricaoProduto(e);
    }
    

    const handleChangeBuscarPedido = (e: string) => {
        setBuscaNumeroPedido(e.replace(/[^0-9]/g, ''));
    }


    return (
        <>
            <Breadcrumb controller="Pedidos" action="Relatorio de pedidos" />
            <div className="p-5 rounded bg-white shadow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row mb-3">
                            <h6>Relatorio de pedidos</h6>
                        </div>
                        <div className="row mb-4">
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar por numero do pedido"
                                    value={buscaNumeroPedido}
                                    onChange={e => handleChangeBuscarPedido(e.target.value)}
                                />
                            </div>
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar por descriçao"
                                    value={buscaDescricaoProduto}
                                    onChange={e => handleChangeBuscarDescricaoProduto(e.target.value.toUpperCase())}
                                />
                            </div>
                            {
                                hasAnyRoles(['ROLE_ADMIN']) &&
                                <div className="col-lg-3">
                                    <Select
                                        options={options}
                                        value={[{ value: 'none', label: 'Selecione o Usuário' }, ...options].find(option => option.value === usuario)}
                                        onChange={e => handleChangeUser(e?.value || 'none')}
                                        placeholder="Selecione o Usuário"
                                        isClearable
                                        styles={{
                                            menuList: (provided) => ({ ...provided, maxHeight: 500, }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999, }),
                                        }}
                                    />
                                </div>
                            }
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4}`}>
                                <select
                                    className="form-select bg-white"
                                    onChange={e => handleChangeEmpresa(e.target.value)}
                                    value={empresa || 'none'}
                                >
                                    <option value={'none'} disabled>
                                        Selecione a Empresa
                                    </option>
                                    {empresas.map((empresa, i) => (
                                        <option key={i} value={empresa.cnpj}>
                                            {`${empresa.nome} - ${empresa.cnpj}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div 
                                className={`col-lg-12 mt-3`} 
                                style={{
                                    display: "flex", 
                                    gap: 10,
                                }}
                            >
                                <input
                                    type="button"
                                    className="btn btn-danger text-uppercase w-100"
                                    value="Limpar Filtro"
                                    onClick={() => handleClearFilter()}
                                />
                                <input
                                    type="button"
                                    className="btn btn-primary text-uppercase w-100"
                                    value="Buscar"
                                    onClick={() => getPedidos()}
                                />
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover fs-6">
                                <thead>
                                    <tr>
                                        <th scope="col" className="d-none d-lg-table-cell">Nº Pedido</th>
                                        <th scope="col">Item</th>
                                        <th scope="col">Data</th>
                                        <th scope="col">Produto</th>
                                        <th scope="col" className="text-end">Qtd. Agendada</th>
                                        <th scope="col" className="text-end">Qtd. Entregue</th>
                                        <th scope="col" className="text-end">Qtd. Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pedidos.map(pedido => (
                                        <tr key={pedido.id}>
                                            <th className="d-none d-lg-table-cell">{pedido.pedido}</th>
                                            <td>{pedido.codigo.slice(-4)}</td>
                                            <td>{formatarStringToData(pedido.dataEntrega)}</td>
                                            <td>{`${pedido.produto} - ${pedido.descricao}`}</td>
                                            <td align="right">
                                                {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 5,
                                                    useGrouping: false,
                                                }).format(Number(pedido.quantAgendada))}
                                            </td>
                                            <td  align="right">
                                                {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 5,
                                                    useGrouping: false,
                                                }).format(Number(pedido.quantEntregada))}
                                            </td>
                                            <td  align="right">
                                                {new Intl.NumberFormat('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 5,
                                                    useGrouping: false,
                                                }).format(Number(pedido.quantidade))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}


export default RelatorioPedidos