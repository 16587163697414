

export const KEY_TOKEN = "authData";

export type LoginResponse={
    access_token: string,
    token_type: string,
    expires_in: number,
    scope: string,
    userName: string,
    userId: number,
    janela: string
}

export type Role = 'ROLE_OPERATOR' | 'ROLE_ADMIN' | undefined;

//Generic
export const saveDataOnStorage = (key: string, obj: any) => {
    localStorage.setItem(key, JSON.stringify(obj));
  };
  
export const removeDataOnStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getDataOnStorage = (key: string) => {
  const str = localStorage.getItem(key) ?? "{}";
  return JSON.parse(str);
};

//Auth
export const saveAuthData = (obj: LoginResponse) => {
  localStorage.setItem(KEY_TOKEN, JSON.stringify(obj));
};

export const getAuthData = () => {
  const str = localStorage.getItem(KEY_TOKEN) ?? "{}";
  return JSON.parse(str) as LoginResponse;
};

export const removeAuthData = () => {
  localStorage.clear();
}

