import { Link, useLocation, useNavigate } from "react-router-dom";
import relogio from 'assets/images/wall-clock.png';

type States = {
    fromSelect: boolean;
}

const Agradecimento = () => {
    const location = useLocation();
    const { fromSelect } = location.state as States;
    const navigate = useNavigate();

    if(!fromSelect){
        navigate("/painel/listar-pedidos")
    };

    return (
        <div className="p-5 rounded bg-white h-auto text-center">
            <div>
                <img src={relogio} className="img-fluid" width={250} alt="" />
            </div>
            <h1 className="mt-3"> Seu agendamento foi enviado, <br/> aguarde o email de confirmação.</h1>
            <Link to="/painel/agendamentos" className="btn btn-primary text-uppercase btn-lg mt-3"> Ver Agendamentos </Link>
        </div>
    )
}

export default Agradecimento;