import { AxiosRequestConfig } from 'axios';
import Breadcrumb from 'components/breadcrumb';
import Loader from 'components/loader';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Carregamento } from 'types/carregamentos';
import { makeRequest } from 'utils/requests';
import { formatarStringToDataEHora } from 'utils/utils';
import { useAuth } from 'contexts/AuthContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';

const Agendamentos = () => {
  const [carregamentos, setCarregamentos] = useState<Carregamento[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { parametros } = useAuth();
  const [status, setStatus] = useState('');
  const [data, setData] = useState('');
  const [carregamentoSelecionado, setCarregamentoSelecionado] = useState<Carregamento>();

  const [show, setShow] = useState(false);

  const handleShow = (carregamento: Carregamento) => {
    setCarregamentoSelecionado(carregamento);
    setShow(true);
  };

  const handleClose = () => {
    setCarregamentoSelecionado(undefined);
    setShow(false);
  };

  const getCarregamentos = useCallback(() => {
    setIsLoading(true);
    const params: AxiosRequestConfig = {
      method: 'GET',
      url: '/carregamento',
      withCredentials: true,
      params: {
        sort: 'dataAgendamento,desc',
        dataAgendamento: data,
        status: status !== 'none' ? status : undefined,
      },
    };
    makeRequest(params)
      .then(response => {
        setCarregamentos(response.data.content);
      })
      .catch(response => toast.error('Erro ao carregar os carregamentos'))
      .finally(() => setIsLoading(false));
  }, [data, status]);

  useEffect(() => {
    getCarregamentos();
  }, [getCarregamentos]);

  const handleCancel = (carregamento: Carregamento) => {
    setIsLoading(true);

    Swal.fire({
      title: 'Tem certeza que deseja\n cancelar ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Não`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    })
      .then(result => {
        if (result.isConfirmed) {
          const params: AxiosRequestConfig = {
            method: 'POST',
            url: `/carregamento/${carregamento.id}/cancelar`,
            withCredentials: true,
          };
          makeRequest(params)
            .then(() => {
              toast.success('Carregamento cancelado com sucesso!');
              getCarregamentos();
            })
            .catch(() => toast.error('Erro ao cancelar carregamento, tente novamente mais tarde!'));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const cancelamentoIsPosible = (carregamento: Carregamento) => {
    const dataAtual = moment().add(parametros.diasToCancel, 'd');
    const dataCarregamento = moment(carregamento.dataAgendamento);
    return !(dataCarregamento < dataAtual || carregamento.status === 'CANCELADO');
  };

  return (
    <>
      <Breadcrumb controller="Agendamentos" action="Lista" />
      <div className="p-5 rounded bg-white shadow">
        <div className="input-group row mb-3">
          <div className="col-lg-5 mb-3">
            <input
              type="date"
              className="form-control bg-white border-3 border-dark"
              onChange={e => setData(e.target.value)}
              value={data}
            />
          </div>
          <div className="col-lg-5 mb-3">
            <select
              className="form-select bg-white border-3 border-dark"
              onChange={e => setStatus(e.target.value)}
              defaultValue={'none'}
            >
              <option value={'none'} disabled>
                Filtrar Status
              </option>
              <option value="PENDENTE">Pendente</option>
              <option value="CONFIRMADO">Confirmado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
          </div>
          <div className="col-lg-2">
            <input
              type="button"
              className="btn btn-primary text-uppercase w-100"
              value="Limpar Filtro"
              onClick={() => {
                setStatus('none')
                setData('')
              }}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <table className="table table-striped table-responsive fs-6">
              <thead>
                <tr>
                  <th scope="col">Fornecedor</th>
                  <th scope="col">Data/Hora</th>
                  <th scope="col">Placa Prevista</th>
                  <th scope="col">Status</th>
                  <th className="text-center"> Cancelamento</th>
                  <th scope="col" className="text-center">
                    Detalhes
                  </th>
                </tr>
              </thead>
              <tbody>
                {carregamentos?.map(carregamento => (
                  <tr key={carregamento.id}>
                    <td className="align-middle">{carregamento.empresa}</td>
                    <td className="col-auto align-middle">{formatarStringToDataEHora(carregamento.dataAgendamento)}</td>
                    <td className="align-middle">{carregamento.placaPrevista}</td>
                    <td className="align-middle">{carregamento.status}</td>
                    <td className="text-center align-middle">
                      {cancelamentoIsPosible(carregamento) ? (
                        <button className="btn btn-sm btn-danger" onClick={() => handleCancel(carregamento)}>
                          <i className="bi-x-circle"></i> Cancelar
                        </button>
                      ) : (
                        <h3 className="btn btn-sm btn-warning fw-bold ">Indisponivel</h3>
                      )}
                    </td>
                    <td className="col-auto align-middle">
                      <Button variant="primary" className="w-100 p-0" onClick={() => handleShow(carregamento)}>
                        <i className="bi bi-eye" style={{ fontSize: '20px' }}></i>
                      </Button>
                    </td>
                  </tr>
                ))}
                <Modal show={show && carregamentoSelecionado ? true : false} onHide={handleClose} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>Detalhes</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th scope="col">Agendamento</th>
                          <th scope="col">Data/Hora</th>
                          <th scope="col">Placa Prevista</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align-middle">{carregamentoSelecionado?.id}</td>
                          <td className="col-auto align-middle">
                            {moment(carregamentoSelecionado?.dataAgendamento).format('DD/MM/yyyy HH:mm')}
                          </td>
                          <td className="align-middle">{carregamentoSelecionado?.placaPrevista}</td>
                          <td className="align-middle">{carregamentoSelecionado?.status}</td>
                        </tr>
                      </tbody>
                    </Table>

                    <p className="mb-2 fw-bold">Produtos</p>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>N° Pedido</th>
                          <th>Descrição</th>
                          <th>Qtd. Agendada</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carregamentoSelecionado?.items.map((produtos, i) => (
                          <tr key={i}>
                            <td>{produtos.codPC}</td>
                            <td>{`${produtos.codProduto} - ${produtos.nome}`}</td>
                            <td>
                              {new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 5,
                                useGrouping: false,
                              }).format(Number(produtos.quantidade))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default Agendamentos;
