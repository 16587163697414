import Breadcrumb from 'components/breadcrumb';
import Calendario from 'components/calendario';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pedido } from 'types/pedidos';
import { formatarStringToData, subtrair } from 'utils/utils';
import { ChangeEvent, useState } from 'react';
import Loader from 'components/loader';
import Form from 'react-bootstrap/Form';

type States = {
  carregamentoPedidos: Pedido[];
  fromList: boolean;
  cgc: string;
  cnpj: string;
};

const SelectData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { carregamentoPedidos, fromList, cgc, cnpj } = location.state as States;
  const [pedidos, setPedidos] = useState<Pedido[]>(
    carregamentoPedidos.map(pr => ({
      ...pr,
      agendamentoAtual: subtrair(pr.quantidade, pr.quantAgendada, pr.quantEntregada),
    })),
  );
  const [isLoading, setIsLoading] = useState(false)



  if (!carregamentoPedidos || fromList === false) {
    navigate('/painel/listar-pedidos');
    toast.error('Você precisa selecionar os pedidos!');
  }

  const handleChangeQuantidade = (e: ChangeEvent<HTMLInputElement>, carregamento: Pedido) => {
    let temp = pedidos.map(pedido => {
      if (pedido.id === carregamento.id) {
        pedido.agendamentoAtual = parseFloat(e.target.value).toFixed(5);
      }
      return pedido;
    });
    setPedidos(temp);
  };

  return (
    <>
      <Breadcrumb controller="Pedidos" action="Selecionar Data" />
      <div className="p-5 rounded bg-white shadow">
        <Calendario
          pedidos={pedidos}
          setIsLoading={setIsLoading}
          cgc={cgc}
          cnpj={cnpj}
        />
        {isLoading && <Loader />}
        {!isLoading && (
          <div className="row mt-5">
            <table className="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col">Nº Pedido</th>
                  <th scope="col">Produto</th>
                  <th scope="col">Descrição</th>
                  <th scope="col">Quantidade</th>
                  <th scope="col">Quantidade Agendada</th>
                  <th scope="col">Quantidade Disponivel</th>
                  <th scope="col">Necessidade</th>
                </tr>
              </thead>
              <tbody>
                {pedidos?.map(pedido => (
                  <tr key={pedido.id} style={{ verticalAlign: "middle" }}>
                    <th>{pedido.pedido}</th>
                    <td>{pedido.produto}</td>
                    <td>{pedido.descricao}</td>
                    <td>
                      <Form.Control
                        type="number"
                        className="bg-white border-3 border-dark"
                        max={subtrair(pedido.quantidade, pedido.quantAgendada, pedido.quantEntregada)}
                        defaultValue={pedido.agendamentoAtual}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChangeQuantidade(e, pedido);
                        }}
                      />
                    </td>
                    <td>
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 5,
                        useGrouping: false,
                      }).format(Number(pedido.quantAgendada))}
                    </td>
                    <td>
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 5,
                        useGrouping: false,
                      }).format(subtrair(pedido.quantidade, pedido.quantAgendada, pedido.quantEntregada))}
                    </td>
                    <td>{formatarStringToData(pedido.dataEntrega)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectData;
