import { AxiosRequestConfig } from "axios";
import Breadcrumb from "components/breadcrumb";
import Loader from "components/loader";
import { useAuth } from "contexts/AuthContext";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Accordion, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { Carregamento } from "types/carregamentos";
import { Empresa } from "types/empresa";
import { makeRequest } from "utils/requests";
import { formatarStringToDataEHora } from "utils/utils";

type Busca = {
    cgc?: string;
    cnpj?: string;
    descProduto?: string;
    numPedido?: string;
    dataInicial?: string;
    dataFinal?: string;
    status?: string;
}


const RelatorioAgendamento = () => {
    const { hasAnyRoles } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [usuarios, setUsuarios] = useState<any[]>([]);
    const [carregamentos, setCarregamentos] = useState<Carregamento[]>([]);
    const [empresas, setEmpresas] = useState<Empresa[]>([]);
    const optionsUser = usuarios.map(usuario => ({ value: usuario.cnpj, label: `${usuario.nome} - ${usuario.cnpj}` }));
    const [carregamentoSelecionado, setCarregamentoSelecionado] = useState<string[]>([]);
    const [busca, setBusca] = useState<Busca>({
        cgc: '',
        cnpj: '',
        descProduto: '',
        numPedido: '',
        dataInicial: '',
        dataFinal: '',
        status: '',
    });



    const getUsuarios = useCallback(() => {
        if (!hasAnyRoles(['ROLE_ADMIN'])) return;
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/usuarios',
            withCredentials: true,
            params: {
                size: 2000,
                sort: 'nome,asc',
            },
        };
        makeRequest(params)
            .then(response => {
                setUsuarios(response.data.content);
            })
            .catch(response => toast.error('Ocorreu um erro ao verificar usuários.'))
            .finally(() => setIsLoading(false));
    }, [hasAnyRoles]);

    useEffect(() => {
        getUsuarios();
    }, [getUsuarios]);

    const getEmpresas = useCallback(() => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/integracao/getEmpresas',
            withCredentials: true,
        };
        makeRequest(params)
            .then(response => {
                setEmpresas(response.data);
            })
            .catch(() => toast.error('Ocorreu um erro ao verificar empresas.'))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        getEmpresas();
    }, [getEmpresas]);

    const getCarregamentos = () => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            method: 'GET',
            url: '/carregamento/relatorio',
            withCredentials: true,
            params: {
                ...busca,
            },
        };
        makeRequest(params)
            .then(response => {
                setCarregamentos(response.data);
            })
            .catch(response => toast.error('Erro ao carregar os carregamentos'))
            .finally(() => setIsLoading(false));
    }


    const handleChangeBusca = (key: keyof Busca, value: string) => {
        setBusca(prevState => ({ ...prevState, [key]: value }));
    }


    const handleClear = () => {
        setBusca({
            cgc: '',
            cnpj: '',
            descProduto: '',
            numPedido: '',
            dataInicial: '',
            dataFinal: '',
            status: '',
        });
    }

    return (
        <>
            <Breadcrumb controller="Pedidos" action="Relatorio de agendamentos" />
            <div className="p-5 rounded bg-white shadow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row mb-3">
                            <h6>Relatorio de agendamentos</h6>
                        </div>
                        <div className="row mb-4">
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4} mb-3`}>
                                <Form.Group>
                                    <Form.Label>Desc. Produto:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={busca?.descProduto}
                                        onChange={e => handleChangeBusca('descProduto', e.target.value.toUpperCase())}
                                    />
                                </Form.Group>
                            </div>
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4} mb-3`}>
                                <Form.Group>
                                    <Form.Label>Num. pedido:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={busca?.numPedido}
                                        onChange={e => handleChangeBusca('numPedido', e.target.value.replace(/[^0-9]/g, ''))}
                                    />
                                </Form.Group>
                            </div>
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4}  mb-3`}>
                                <Form.Group>
                                    <Form.Label>Data inicial:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={busca?.dataInicial}
                                        onChange={e => handleChangeBusca('dataInicial', e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4}  mb-3`}>
                                <Form.Group>
                                    <Form.Label>Data Final:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={busca?.dataFinal}
                                        onChange={e => handleChangeBusca('dataFinal', e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className={`col-lg-${hasAnyRoles(['ROLE_ADMIN']) ? 3 : 4} mb-3`}>
                                <Form.Group>
                                    <Form.Label>Status:</Form.Label>
                                    <Form.Select
                                        value={busca?.status}
                                        onChange={e => handleChangeBusca('status', e.target.value)}
                                    >
                                        <option value="" disabled>Selecione</option>
                                        <option value="PENDENTE">Pendente</option>
                                        <option value="CONFIRMADO">Confirmado</option>
                                        <option value="CANCELADO">Cancelado</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            {
                                hasAnyRoles(['ROLE_ADMIN']) &&
                                <>

                                    <div className="col-lg-3 mb-3">
                                        <Form.Group>
                                            <Form.Label>Usuário:</Form.Label>
                                            <Select
                                                options={optionsUser}
                                                value={[{ value: '', label: 'Selecione' }, ...optionsUser].find(option => option.value === busca?.cgc)}
                                                onChange={e => handleChangeBusca('cgc', e?.value)}
                                                placeholder="Selecione"
                                                isClearable
                                                styles={{
                                                    menuList: (provided) => ({ ...provided, maxHeight: 500, }),
                                                    menu: (provided) => ({ ...provided, zIndex: 9999, }),
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                </>
                            }
                            <div className={`col-lg-3 mb-3`}>
                                <Form.Group>
                                    <Form.Label>Empresa:</Form.Label>
                                    <Form.Select
                                        value={busca?.cnpj}
                                        onChange={e => handleChangeBusca('cnpj', e.target.value)}
                                    >
                                        <option value="" disabled>Selecione</option>
                                        {empresas.map((empresa, i) => (
                                            <option key={i} value={empresa.cnpj}>
                                                {`${empresa.nome} - ${empresa.cnpj}`}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div
                                className={`col-lg-12 mt-3`}
                                style={{
                                    display: "flex",
                                    gap: 10,
                                }}
                            >
                                <input
                                    type="button"
                                    className="btn btn-danger text-uppercase w-100"
                                    value="Limpar Filtro"
                                    onClick={() => handleClear()}
                                />
                                <input
                                    type="button"
                                    className="btn btn-primary text-uppercase w-100"
                                    value="Buscar"
                                    onClick={() => getCarregamentos()}
                                />
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Accordion activeKey={carregamentoSelecionado}>
                                <table className="table table-hover fs-6">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Data Agendamento</th>
                                            {hasAnyRoles(['ROLE_ADMIN']) && <th scope="col">Usuário</th>}
                                            <th scope="col">Placa</th>
                                            <th scope="col">Qtd. Produtos</th>
                                            <th scope="col">Qtd.</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {carregamentos.map((carregamento) => (
                                            <Fragment key={carregamento.id}>
                                                <tr>
                                                    <td>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                if (carregamentoSelecionado.includes(String(carregamento.id))) {
                                                                    setCarregamentoSelecionado(state => state.filter(id => id !== String(carregamento.id)));
                                                                    return;
                                                                }
                                                                setCarregamentoSelecionado(state => [...state, String(carregamento.id)]);
                                                            }}
                                                        >
                                                            {carregamentoSelecionado.includes(String(carregamento.id)) ? <ChevronUp/> : <ChevronDown />}
                                                        </button>
                                                    </td>
                                                    <td>{formatarStringToDataEHora(carregamento.dataAgendamento)}</td>
                                                    {hasAnyRoles(['ROLE_ADMIN']) && <td>{carregamento.empresa}</td>}
                                                    <td>{carregamento.placaPrevista}</td>
                                                    <td>{carregamento.items.length}</td>
                                                    <td>{`${new Intl.NumberFormat('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 5,
                                                        useGrouping: false,
                                                    }).format(carregamento.items.reduce((acc, item) => acc + Number(item.quantidade), 0))}`}</td>
                                                    <td>{carregamento.status}</td>
                                                </tr>
                                                {
                                                    !carregamentoSelecionado.includes(String(carregamento.id)) ? null : (
                                                        <tr>
                                                            <td colSpan={hasAnyRoles(["ROLE_ADMIN"]) ? 8 : 7} className="p-0">
                                                                <div className="bg-white d-flex flex-1 flex-column py-3 px-4">
                                                                    <p className="my-0 fw-bold">Produtos</p>
                                                                    <Table striped bordered size="sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>N° Pedido</th>
                                                                                <th>Descrição</th>
                                                                                <th>Qtd. Agendada</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {carregamento?.items.map((produtos, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{produtos.codPC}</td>
                                                                                    <td>{`${produtos.codProduto} - ${produtos.nome}`}</td>
                                                                                    <td>
                                                                                        {new Intl.NumberFormat('pt-BR', {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 5,
                                                                                            useGrouping: false,
                                                                                        }).format(Number(produtos.quantidade))}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </Accordion>
                        </div>
                    </>
                )}
            </div>
        </>
    );

}


export default RelatorioAgendamento;