import {AxiosRequestConfig} from "axios";
import Breadcrumb from "components/breadcrumb";
import Loader from "components/loader";
import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {makeRequest} from "utils/requests";
import {useNavigate, useParams} from "react-router-dom";

const EditConfiguracao = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, setValue} = useForm();
    const {id} = useParams();
    const add = id === "add";
    const navigate = useNavigate();

    const getparametros = useCallback(() => {
        if(!add){
            setIsLoading(true);
            const params: AxiosRequestConfig = {
                method: 'GET',
                url: `/parametros/${id}`,
                withCredentials: true,
            }
            makeRequest(params)
                .then(response => {
                    setValue('diasMin', response.data.diasMin);
                    setValue('diasMax', response.data.diasMax);
                    setValue('diasToCancel', response.data.diasToCancel);
                    setValue('horarioMin', response.data.horarioMin);
                    setValue('horarioMax', response.data.horarioMax);
                    setValue('intervaloHora',response.data.intervaloHora);
                    setValue('janela', response.data.janela);
                    setValue('considerarDataAtual', response.data.considerarDataAtual.toString());
                })
                .catch(response => toast.error('Erro ao carregar os parametros'))
                .finally(() => setIsLoading(false));
        }
    }, [add, id, setValue]);

    useEffect(() => {
        getparametros();
    }, [getparametros]);


    const onSubmit = (data: any) => {
        setIsLoading(true);
        data.considerarDataAtual = data.considerarDataAtual === 'true';
        const params: AxiosRequestConfig = {
            method: add ? 'POST' : 'PUT',
            url: `/parametros${!add && `/${id}`}`,
            withCredentials: true,
            data,
        }
      makeRequest(params)
          .then(() => {
              navigate('/painel/configuracoes');
            toast.success("Configurações salvas com sucesso!");
          })
          .catch(() => toast.error("Ocorreu um erro ao salvar configurações."))
          .finally(() => setIsLoading(false));
    };



    return (
        <>
            <Breadcrumb controller="Sistema" action="Configurações"/>
            <div className="p-5 rounded bg-white shadow">
              {isLoading ? <Loader/> : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Janela:</label>
                                </div>
                                <div className="col-lg-5">
                                    {/*<Form.Select*/}
                                    {/*    {...register("janela", {required: true,})}*/}
                                    {/*    className=" bg-white"*/}
                                    {/*>*/}
                                    {/*    <option value="1">1</option>*/}
                                    {/*    <option value="2">2</option>*/}
                                    {/*    <option value="3">3</option>*/}
                                    {/*</Form.Select>*/}
                                    <input
                                        type="number"
                                        className="form-control disabled"
                                        placeholder=""
                                        readOnly={true}
                                       {...register("janela", {required: true,})}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Dias minimos para agendamento:</label>
                                </div>
                                <div className="col-lg-5">
                                    <input type="number" className="form-control bg-white"
                                           placeholder="" {...register("diasMin", {
                                        required: true,
                                    })} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Dias máximos para agendamento:</label>
                                </div>
                                <div className="col-lg-5">
                                    <input type="number" className="form-control bg-white"
                                           placeholder="" {...register("diasMax", {
                                        required: true,
                                    })} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Prazo minimo para cancelamento:</label>
                                </div>
                                <div className="col-lg-5">
                                    <input type="number" className="form-control bg-white"
                                           placeholder="" {...register("diasToCancel", {
                                        required: true,
                                    })} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Horario minimo para agendamento:</label>
                                </div>
                                <div className="col-lg-5">
                                    <input type="time" className="form-control bg-white"
                                           placeholder="" {...register("horarioMin", {required: true})} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Horario máximo para agendamento:</label>
                                </div>
                                <div className="col-lg-5">
                                    <input type="time" className="form-control bg-white"
                                           placeholder="" {...register("horarioMax", {required: true})} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Intervalo de tempo entre agendamentos:</label>
                                </div>
                                <div className="col-lg-5">
                                    <input type="time" className="form-control bg-white"
                                           placeholder="" {...register("intervaloHora", {required: true})} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label className="form-label fw-bold">Considerar data atual:</label>
                                </div>
                                <div className="col-lg-5">
                                    <select 
                                        className="form-select bg-white"
                                        defaultValue={'true'}
                                        {...register("considerarDataAtual", {required: true})}
                                    >
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 col-lg-4 offset-lg-4">
                        <input type="submit" className="btn btn-primary btn-lg" value="Salvar"/>
                    </div>
                </form>
              )}
            </div>
        </>
    )
}

export default EditConfiguracao;
