import { Card, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <Container fluid className="vh-100 vw-100 bg-secondary">
        <Row className="vh-100 vw-100 d-flex justify-content-center align-items-center">
            <Col xl={4} xs={12}  >
                <Card className="rounded shadow p-3 bg-white">                    
                    <Card.Body>
                        <Outlet />                       
                    </Card.Body>
                </Card>
            </Col>            
        </Row>
    </Container>
  );
};


export default AuthLayout;