import {createContext, ReactNode, useContext, useState} from "react";
import {KEY_TOKEN, LoginResponse} from "utils/storage";
import {AuthRole, getTokenData} from "utils/auth";
import {useLocalStorage} from "utils/hooks";
import {useNavigate} from "react-router-dom";
import {LoginData, makeRequest, makeRequestLogin} from "utils/requests";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";
import {Parametros} from "types/parametros";

type Props = {
    children: ReactNode;
}

export type AuthContext = {
    signIn: (data: LoginData) => void;
    isAuthenticated: () => boolean;
    name: string;
    hasAnyRoles: (roles: AuthRole[]) => boolean;
    authData: LoginResponse;
    parametros: Parametros;
}

const AuthContextType = createContext<AuthContext>({} as AuthContext);


const AuthProvider = ({children}: Props) => {
    const [authData, setAuthData] = useLocalStorage<LoginResponse>(KEY_TOKEN);
    const [parametros , setParametros] = useLocalStorage<Parametros>('parametros', {} as Parametros);
    const [name, setName] = useState('')
    const navigate = useNavigate();

    const hasAnyRoles = (roles:AuthRole[]):boolean => {
        const tokenData = getTokenData();
        if(roles.length === 0 ) return true;
        if(tokenData !== undefined){
            return roles.some(role => tokenData.authorities.includes(role));
        }
        return false;
    }

    const isAuthenticated = ():boolean =>{
        const tokenDataDecoded = getTokenData();
        return !!(tokenDataDecoded && tokenDataDecoded.exp * 1000 > Date.now());
    }

    const signIn = async ({username, password}: LoginData) => {
        await makeRequestLogin({username, password})
            .then((response)=>{
                setAuthData(response.data);
                getParametros(response.data.janela);
                setName(response.data.userName);
            })
            .catch(() => {toast.error("Ocorreu um erro ao fazer login!")})
    }


    const getParametros = (janela:string) => {
        makeRequest({method: 'GET',url: `/parametros/janela/${janela}`, withCredentials: false})
            .then((res: AxiosResponse<Parametros>) => {
                setParametros(res.data);
                if(hasAnyRoles(['ROLE_ADMIN'])){
                    navigate("/painel/agendamentos", {replace: true});
                }else{
                    navigate("/painel/listar-pedidos", {replace: true});
                }
            })
            .catch(() => {
                toast.error('Erro ao buscar parametros')
                navigate('/logout');
            });
    }

    return (
        <AuthContextType.Provider value={{signIn, isAuthenticated, name, hasAnyRoles, authData, parametros}}>
            {children}
        </AuthContextType.Provider>
    );

}

export default AuthProvider;

export const useAuth = () => useContext(AuthContextType);