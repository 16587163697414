import { AxiosRequestConfig } from "axios";
import { cnpj } from "cpf-cnpj-validator";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { makeRequest } from "utils/requests";
import { ReactComponent as Logo } from 'assets/images/Grupo-Multitecnica.svg';

type FormState = {
    username: string;
}

const EsqueciSenha = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormState>();
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    const onSubmit = (data: FormState) => {
        data.username = data.username.replace(/\D/g, "");
        if(cnpj.isValid(data.username)){
            const params: AxiosRequestConfig = {
                method: 'GET',
                url: `/usuarios/validar-cnpj/${data.username}`,
                withCredentials: false
            }
            makeRequest(params)
            .then(response => {
                if(response.data === true){
                    const params: AxiosRequestConfig = {
                        method: 'POST',
                        url: `/usuarios/resetar-senha/${data.username}`,
                        withCredentials: false
                    }
                    makeRequest(params)
                    .then(() => {
                        navigate('/login')
                        toast.success("Enviado com sucesso! Consulte eu email para mais informações.")   
                    })
                    .catch(() => toast.error("Erro de conexão!"));                                    
                }else{
                    toast.error("CNPJ Inválido ou não cadastrado!")
                }
            }).catch(() => toast.error("Erro de conexão!"));           
        }else{
            setHasError(true);
            toast.error("CNPJ Inválido ou não cadastrado!")
        }
    }

    return (
        
        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            <Logo className="img-fluid mb-4" />
            <h1 className="h3 fw-normal">Digite seu CNPJ para recuperar a senha.</h1>
            <div className="form-floating mb-3 mt-1">                   
                <input 
                    type="text" 
                    placeholder="00000000000000"                                              
                    className={`form-control bg-white ${errors.username ? 'is-invalid' : ''}`}                            
                    {...register("username", { required: true })}
                />
                <label htmlFor="username">CNPJ</label>                        
            </div>
            {hasError && <div className="invalid-feedback">CNPJ Inválido</div>}                   
            <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Enviar</button>
            <p className="mt-5 mb-3 text-muted">&copy; 2021</p>
        </form>
            
    )
}

export default EsqueciSenha;